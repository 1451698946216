<!-- eslint-disable no-param-reassign -->
<!-- eslint-disable prefer-destructuring -->
<template>
  <div>
    <v-tabs
      v-model="tabPosition"
      show-arrows
      class="v-tabs-pill mb-3"
    >
      <v-tab v-if="userType == 'Pupil'">
        <span>Leerlingen</span>
      </v-tab>
      <v-tab>
        <span>Inloggegevens</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tabPosition"
      style="background: transparent"
    >
      <v-tab-item v-if="userType == 'Pupil'">
        <v-tabs vertical>
          <v-tab
            v-for="uploader in pupilUploaders"
            :key="uploader.name"
          >
            {{ uploader.name }}
          </v-tab>

          <v-tab-item
            v-for="uploader in pupilUploaders"
            :key="uploader.name"
          >
            <!-- Titel -->
            <v-card-title>
              {{ uploader.name }}
            </v-card-title>

            <!-- Beschrijving -->
            <v-card-text>
              {{ uploader.description }}
            </v-card-text>

            <!-- Bestandsupload -->
            <v-card-text class="mt-n5">
              <v-form
                :ref="'uploader-' + uploader.easyName"
                lazy-validation
                @submit.prevent="handlePupilUpload(uploader)"
              >
                <v-file-input
                  v-model="uploader.file"
                  accept=".xlsx"
                  label="Excel bestand"
                  chips
                  :loading="uploader.loading"
                  @change="handleFileUpload($event, uploader)"
                ></v-file-input>
                <v-btn
                  class="mt-4"
                  color="primary"
                  type="submit"
                >
                  Importeren
                </v-btn>
              </v-form>
            </v-card-text>

            <v-card-actions
              v-if="uploader.easyName === 'googleWorkspace'"
              class="caption"
            >
              Door op te slaan worden reeds bestaande gegevens overschreven
            </v-card-actions>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <!-- Credential uploaders -->
      <v-tab-item>
        <v-row class="match-height">
          <!-- accept -->
          <v-col
            v-for="uploader in loginDetailsUploaders"
            :key="uploader.name"
            cols="12"
            sm="6"
          >
            <v-card>
              <!-- Titel -->
              <v-card-title>
                {{ uploader.name }}
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  class="me-n3 mt-n2"
                >
                  <v-icon
                    @click="handleDownloadTemplate(uploader)"
                  >
                    {{ icons.mdiDownloadOutline }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <!-- Beschrijving -->
              <v-card-text>
                {{ uploader.description }}
              </v-card-text>

              <!-- Bestandsupload -->
              <v-card-text>
                <v-form
                  :ref="'uploader-' + uploader.easyName"
                  lazy-validation
                  @submit.prevent="handleLoginDetailsUpload(uploader)"
                >
                  <v-file-input
                    v-model="uploader.file"
                    accept=".xlsx"
                    label="Excel bestand(en)"
                    chips
                    multiple
                    :loading="uploader.loading"
                    @change="handleFileUpload($event, uploader)"
                  ></v-file-input>
                  <v-btn
                    class="mt-4"
                    color="primary"
                    type="submit"
                  >
                    Importeren
                  </v-btn>
                </v-form>
              </v-card-text>

              <v-card-actions class="caption">
                Bij het importeren worden reeds bestaande gegevens overschreven
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from '@axios'
import {
  mdiAccessPoint, mdiAccountOutline, mdiDownloadOutline, mdiLockOutline,
} from '@mdi/js'

export default {
  props: {
    userType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tabPosition: 0,
    pupilUploaders: [
      {
        name: 'Broeckx on web',
        easyName: 'broeckxonweb',
        type: 'pupil',
        description: 'Importeer leerlingen vanuit Broeckx on web',
        fileUrl: 'https://google.com',
        rules: [v => !!v || 'Een bestand is verplicht'],
        uploadedFiles: null,
        loading: false,
      },
      {
        name: 'Google Workspace',
        easyName: 'googleWorkspace',
        type: 'pupil',
        description: 'Importeer leerlingen vanuit Google Workspace',
        fileUrl: 'https://google.com',
        rules: [v => !!v || 'Een bestand is verplicht'],
        uploadedFiles: null,
        loading: false,
      },
    ],
    loginDetailsUploaders: [],
    icons: {
      mdiAccountOutline,
      mdiLockOutline,
      mdiAccessPoint,
      mdiDownloadOutline,
    },
  }),
  async mounted() {
    const websitesResponse = await axios.get('/website')
    const { websites } = websitesResponse.data
    const mappedWebsites = websites.map(website => ({
      name: website.name,
      easyName: website.easyId,
      canBeUsedByStudents: website.canBeUsedByStudents,
      canBeUsedByTeachers: website.canBeUsedByTeachers,
      type: 'loginDetails',
      description: `Importeer wachtwoorden voor ${website.name}`,
      websiteId: website.id,
      rules: [v => !!v || 'Een bestand is verplicht'],
      uploadedFiles: null,
      loading: false,
    }))

    // If 'userType' is set to 'Pupil', only show the uploaders that can be used by students
    if (this.userType === 'Pupil') {
      this.loginDetailsUploaders = mappedWebsites.filter(website => website.canBeUsedByStudents)
    } else {
      this.loginDetailsUploaders = mappedWebsites.filter(website => website.canBeUsedByTeachers)
    }

    console.log(this.loginDetailsUploaders)
  },
  methods: {
    async handleFileUpload(event, uploader) {
      console.log(event)
      const newUploader = this.getUploaderByEasyName(uploader.easyName, uploader.type)
      newUploader.uploadedFiles = event
    },
    getUploaderByEasyName(easyName, uploaderType) {
      if (uploaderType === 'pupil') {
        return this.pupilUploaders.find(uploader => uploader.easyName === easyName)
      }

      return this.loginDetailsUploaders.find(uploader => uploader.easyName === easyName)
    },
    async handlePupilUpload(uploader) {
      console.log('handlePupilUpload')
      const newUploader = this.getUploaderByEasyName(uploader.easyName, uploader.type)
      newUploader.loading = true

      if (!uploader.file) {
        newUploader.loading = false

        return this.$toast('error', 'Oeps!', 'Je vergat een bestand te selecteren')
      }

      const formData = new FormData()
      formData.append('file', uploader.file)

      // Call to API /import/pupil/{uploader.easyName} via axios
      const school = JSON.parse(localStorage.getItem('school'))
      await axios
        .post(`/school/${school.id}/import/pupil/${uploader.easyName}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if (response.data.totalRows === response.data.successfullyAdded) {
            this.$toast('success', 'Joepie!', 'Het bestand is volledig verwerkt.')
          } else if (response.data.successfullyAdded === 0) {
            // We need to create a detailed error message view
            try {
              const fileErrors = response.data.errors.join('<br>')
              this.$swalHTML('error', 'Oeps!', fileErrors)
            } catch (err) {
              this.$toast('error', 'Oei!', 'Het bestand is niet volledig verwerkt')
            }
          } else {
            // We need to create a detailed error message view
            try {
              const fileErrors = response.data.errors.join('<br>')
              this.$swalHTML('error', 'Oeps!', fileErrors)
            } catch (err) {
              this.$toast('error', 'Oei!', 'Het bestand is niet verwerkt')
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          newUploader.loading = false
          newUploader.file = null
        })

      return true
    },
    async handleLoginDetailsUpload(uploader) {
      const newUploader = this.getUploaderByEasyName(uploader.easyName, uploader.type)
      newUploader.loading = true

      const formData = new FormData()
      newUploader.uploadedFiles.forEach((uploadedFile, index) => {
        formData.append(`file${index}`, uploadedFile)
      })

      const school = JSON.parse(localStorage.getItem('school'))

      // Call to API /import/pupil/{uploader.easyName} via axios
      await axios
        .post(`/school/${school.id}/import/logindetails/${uploader.easyName}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if (response.data.success) {
            this.$toast('success', 'Joepie!', 'Het bestand is volledig verwerkt.')
          } else {
            // We need to create a detailed error message view
            const someArrary = response.data.errors.map(file => {
              const fileErrors = file.errors.join('<br>')

              return `<b>${file.filename}</b><br>${fileErrors}<br><br>`
            })
            this.$swalHTML('error', 'Oeps!', someArrary)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          newUploader.loading = false
          newUploader.files = null
        })
    },
    async handleDownloadTemplate(uploader) {
      axios({
        method: 'get',
        url: `website/${uploader.websiteId}/example`,
        responseType: 'arraybuffer',
      })
        .then(response => {
          this.forceFileDownload(response, `${uploader.easyName}.xlsx`)
        })
        .catch(() => alert('Er ging iets mis. Probeer het later opnieuw.'))
    },
    forceFileDownload(response, title) {
      console.log(title)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
  },
}
</script>

<style>
.swal2-popup {
  font-family: 'Inter', sans-serif;
}
</style>
